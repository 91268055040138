import { NavLink } from 'react-router-dom';
import { createLink } from '../../utils/helperFunctions';
import { Logo } from '../../svgs/Logo';

interface Props {
  className?: string;
}

const DEFAULT_CLASSES = 'relative z-10 flex-grow w-32 flex mb-6';

export const MainLogo: React.FC<Props> = ({ className }: Props) => {
  return (
    <NavLink
      to={createLink('/papers')}
      className={className ?? DEFAULT_CLASSES}
    >
      <Logo />
    </NavLink>
  );
};
