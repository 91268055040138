import { AppState } from '../store';

export const selectPapers = (state: AppState) => state.papers.papers;
export const selectPapersLoading = (state: AppState) => state.papers.loading;
export const selectPapersError = (state: AppState) => state.papers.error;

export const selectSearchError = (state: AppState) => state.papers.searchError;
export const selectSearchLoading = (state: AppState) =>
  state.papers.searchLoading;
export const selectRequestAbortedForNew = (state: AppState) =>
  state.papers.requestAbortedForNew;
export const selectSearchPapers = (state: AppState) =>
  state.papers.searchPapers;

export const selectPapersHome = (state: AppState) => state.papers.papersHome;
export const selectPapersHomeLoading = (state: AppState) =>
  state.papers.papersHomeLoading;
export const selectPapersHomeError = (state: AppState) =>
  state.papers.papersHomeError;
export const selectPapersHomeCount = (state: AppState) =>
  state.papers.papersHome.count;
