import { createSlice } from '@reduxjs/toolkit';
import {
  CITES_CITEDBY_RADIO_STATES,
  TOPICS_COMMUNITIES_RADIO_STATES
} from '../../routes/constants';

export interface RadioState {
  topicsCommunitiesRadioState: string;
  papersDiscussionsRadioState: string | null;
  citesCitedByRadioState: string;
}

const initialState: RadioState = {
  topicsCommunitiesRadioState: TOPICS_COMMUNITIES_RADIO_STATES.PAPERS,
  papersDiscussionsRadioState: null,
  citesCitedByRadioState: CITES_CITEDBY_RADIO_STATES.CITES
};

const radioStateSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setTopicsCommunitiesRadioState: (state, action) => {
      state.topicsCommunitiesRadioState = action.payload;
    },
    setPapersDiscussionsRadioState: (state, action) => {
      state.papersDiscussionsRadioState = action.payload;
    },
    setCitesCitedByState: (state, action) => {
      state.citesCitedByRadioState = action.payload;
    }
  }
});

export const {
  setTopicsCommunitiesRadioState,
  setPapersDiscussionsRadioState,
  setCitesCitedByState
} = radioStateSlice.actions;
export default radioStateSlice.reducer;
