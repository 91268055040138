import DOMPurify from 'dompurify';
import { PUBLIC_URL_PREFIX } from './constants';
import { IPaperFilterData } from '../types/filter.type';

export const isFilterActive = (filterData: IPaperFilterData): boolean => {
  return (
    (filterData.title && filterData.title.trim() !== '') ||
    (filterData.author && filterData.author.trim() !== '') ||
    (filterData.journal && filterData.journal.trim() !== '') ||
    filterData.publishedDateFrom !== null ||
    filterData.publishedDateTo !== null
  );
};

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

/**
 * Combines common URL prefix (PUBLIC_URL_PREFIX const) with a path.
 *
 * @param path - The URL path.
 * @returns A URL path properly combined with common prefix.
 */
export function createLink(path?: string): string {
  return `${PUBLIC_URL_PREFIX ? '/' + PUBLIC_URL_PREFIX : ''}${path && !/^\//.test(path) ? '/' : ''}${path}`;
}

export const randomInteger = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const sanitizeTextReturnWithoutTags = (html: string) => {
  return DOMPurify.sanitize(html, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] });
};
