import { FC } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Link } from 'react-router-dom';

import {
  subscribeToNews,
  unsubscribeFromNews,
  useAppDispatch
} from '../../state';
import { Tooltip } from '../core/tooltip';
import { Subscribe } from '../../svgs/Subscribe';
import { Unsubscribe } from '../../svgs/Unsubscribe';
import { formatDate } from '../../utils/userPostHelper';
import { clearSubscriptions } from '../../state/slices/userReducer';
import { useNotifications } from '../notificationProvider/hooks/useNotifications';

interface ICommunitySubscription {
  subscriptionId: number;
  createdUtc: string;
  deletedUtc: string | null;
  communityName: string;
  communityUrlKey: string;
}

interface SubscriptionsListProps {
  items: ICommunitySubscription[];
  viewMode: 'single' | 'triple';
}

export const SubscriptionsList: FC<SubscriptionsListProps> = ({
  items,
  viewMode
}) => {
  const dispatch = useAppDispatch();
  const { notify } = useNotifications();
  const isTripleView = viewMode === 'triple';

  const handleUnsubscribe = async (
    communityUrlKey: string,
    communityName: string
  ) => {
    try {
      await dispatch(unsubscribeFromNews({ urlKey: communityUrlKey })).unwrap();
      dispatch(clearSubscriptions());
      notify('success', `You are no longer subscribed to ${communityName}`, {
        toastId: `unsubscribe-${communityUrlKey + Math.random() * 10}`
      });
    } catch (error: any) {
      notify('error', `Unsubscribe failed: ${error.message || error}`, {
        toastId: `unsubscribe-error-${communityUrlKey + Math.random() * 10}}`
      });
    }
  };

  const handleReSubscribe = async (
    communityUrlKey: string,
    communityName: string
  ) => {
    try {
      await dispatch(subscribeToNews({ urlKey: communityUrlKey })).unwrap();
      dispatch(clearSubscriptions());
      notify('success', `You are re-subscribed to ${communityName}`, {
        toastId: `subscribe-${communityUrlKey + Math.random() * 10}`
      });
    } catch (error: any) {
      notify('error', `Subscribe failed: ${error.message || error}`, {
        toastId: `subscribe-error-${communityUrlKey + Math.random() * 10}`
      });
    }
  };

  return (
    <ResponsiveMasonry
      columnsCountBreakPoints={{
        350: 1,
        600: isTripleView ? 2 : 1,
        900: isTripleView ? 3 : 1
      }}
      className="w-full cites-citedby-tabs-container"
    >
      <Masonry gutter="14px">
        {items.map((sub) => {
          const subscribedDate = formatDate(sub.createdUtc);
          const unsubscribedDate = sub.deletedUtc
            ? formatDate(sub.deletedUtc)
            : null;
          const isCurrentlySubscribed = !sub.deletedUtc;

          return (
            <div
              key={sub.subscriptionId}
              className="subscription-item item border border-gray-200 rounded p-4 shadow-sm"
            >
              <div className="itemTitle font-bold flex mb-3 ">
                <div className="text-sm text-gray-900 hover:text-primary-500 transition-smooth-color leading-tight cursor-pointer">
                  <Link
                    to={`/community/${sub.communityUrlKey}/papers`}
                    className="community-link font-bold"
                  >
                    {sub.communityName}
                  </Link>
                </div>
              </div>
              <div className="flex subs_content mt-2">
                <div className="subscription-dates text-sm text-gray-600">
                  <div>Subscribed: {subscribedDate}</div>
                  {unsubscribedDate && (
                    <div>Unsubscribed: {unsubscribedDate}</div>
                  )}
                </div>
                <div className="subscription-actions">
                  {isCurrentlySubscribed ? (
                    <div
                      onClick={() =>
                        handleUnsubscribe(
                          sub.communityUrlKey,
                          sub.communityName
                        )
                      }
                      className="cursor-pointer flex items-center"
                    >
                      <Tooltip
                        text="Unsubscribe if you no longer wish to receive updates from this community. You’re welcome back anytime!"
                        className="tooltipSubs"
                      >
                        Unsubscribe <Unsubscribe />
                      </Tooltip>
                    </div>
                  ) : (
                    <div
                      onClick={() =>
                        handleReSubscribe(
                          sub.communityUrlKey,
                          sub.communityName
                        )
                      }
                      className="cursor-pointer flex items-center"
                    >
                      <Tooltip
                        text="Stay informed with the latest research, discussions, and updates from this community!"
                        className="tooltipSubs"
                      >
                        Re-Subscribe <Subscribe />
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </Masonry>
    </ResponsiveMasonry>
  );
};
