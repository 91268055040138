import { AppState } from '../store';

export const selectDiscussions = (state: AppState) =>
  state.discussions.discussions;
export const selectDiscussionsLoading = (state: AppState) =>
  state.discussions.loading;
export const selectDiscussionsError = (state: AppState) =>
  state.discussions.error;
export const selectDiscussionsCount = (state: AppState) =>
  state.discussions.discussionsCount;

export const selectDiscussionsHome = (state: AppState) =>
  state.discussions.discussionsHome;
export const selectDiscussionsHomeLoading = (state: AppState) =>
  state.discussions.discussionsHomeLoading;
export const selectDiscussionsHomeError = (state: AppState) =>
  state.discussions.discussionsHomeError;
export const selectDiscussionsHomeCount = (state: AppState) =>
  state.discussions.discussionsHomeCount;
