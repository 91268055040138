import { useAppDispatch, useAppSelector } from '../../state';
import {
  setPaperCommunitySortMethod,
  setPaperHomeSortMethod,
  setPaperTopicSortMethod
} from '../../state/actions/sortingActions';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import qs from 'qs';
import { PapersCommunityList } from './papers-community-list';
import { PapersTopicList } from './papers-topic-list';
import { ViewModeToggle } from '../viewModeToggle';
import { PapersFilterList } from './papers-filter-list';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useFilterPagination } from './hooks/useFilterPagination';
import {
  selectPapers,
  selectPapersHome,
  selectPapersHomeLoading,
  selectPapersLoading
} from '../../state/selectors/papersSelector';
import {
  PAPER_COMMUNITY_SORT_METHOD_DISPLAY_NAMES,
  PAPER_HOME_SORT_METHOD_DISPLAY_NAMES,
  PAPER_TOPIC_SORT_METHOD_DISPLAY_NAMES,
  PaperCommunitySortMethod,
  PaperHomeSortMethod,
  PaperTopicSortMethod
} from '../../utils/sortingConst';
import { SortingButtonsContainer } from '../sortingButtons';
import { selectGeneralViewMode } from '../../state/selectors/viewModeSelector';
import { PapersHomeList } from './papers-home-list';
import { SearchResultsSummary } from '../../routes/search/searchResultsSummary';

interface PapersContentProps {
  isCommunity?: boolean;
  isHome?: boolean;
}

export const PapersList: FC<PapersContentProps> = ({ isCommunity, isHome }) => {
  const dispatch = useAppDispatch();
  const { urlKey } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const viewMode = useAppSelector(selectGeneralViewMode);
  const isTripleView = viewMode === 'triple';

  const sortMethodsToDisplay = isHome
    ? PAPER_HOME_SORT_METHOD_DISPLAY_NAMES
    : isCommunity
      ? PAPER_COMMUNITY_SORT_METHOD_DISPLAY_NAMES
      : PAPER_TOPIC_SORT_METHOD_DISPLAY_NAMES;

  const papersSortMethod = useAppSelector((state) => {
    if (isHome) {
      return state.sort.paperHomeSortMethod;
    }
    if (isCommunity) {
      return state.sort.paperCommunitySortMethod;
    }
    return state.sort.paperTopicSortMethod;
  });

  const queryParams = useQueryParams();
  const initialPage = queryParams.p ? parseInt(queryParams.p as string, 10) : 1;
  const [currentPaperPage, setCurrentPaperPage] = useState(initialPage);
  const { updatePageForFilter } = useFilterPagination();

  const isFilterActive = Boolean(
    queryParams.title ||
      queryParams.author ||
      queryParams.journal ||
      queryParams.publishedDateFrom ||
      queryParams.publishedDateTo
  );

  const papers = useAppSelector((state) =>
    isHome ? selectPapersHome(state) : selectPapers(state)
  );

  const loading = useAppSelector((state) =>
    isHome ? selectPapersHomeLoading(state) : selectPapersLoading(state)
  );

  const handleSortingPapersChange = useCallback(
    (
      method:
        | PaperCommunitySortMethod
        | PaperTopicSortMethod
        | PaperHomeSortMethod
    ) => {
      if (isHome) {
        dispatch(setPaperHomeSortMethod(method as PaperHomeSortMethod));
      } else if (isCommunity) {
        dispatch(
          setPaperCommunitySortMethod(method as PaperCommunitySortMethod)
        );
      } else {
        dispatch(setPaperTopicSortMethod(method as PaperTopicSortMethod));
      }
    },
    [dispatch, isCommunity, isHome]
  );

  const updatePage = (newPage: number) => {
    setCurrentPaperPage(newPage);

    const newQueryParams = {
      ...queryParams,
      p: newPage.toString()
    };

    const newSearch = qs.stringify(newQueryParams, { addQueryPrefix: true });
    navigate({ pathname: location.pathname, search: newSearch });
  };

  useEffect(() => {
    const newPage = queryParams.p ? parseInt(queryParams.p as string, 10) : 1;
    if (newPage !== currentPaperPage) {
      setCurrentPaperPage(newPage);
    }
  }, [queryParams, currentPaperPage]);

  return (
    <div className="w-full h-full">
      {!isFilterActive &&
        (loading || (papers.page?.length && papers.page.length > 0)) && (
          <>
            <SortingButtonsContainer
              sortMethods={sortMethodsToDisplay}
              currentSortMethod={papersSortMethod}
              onSortMethodChange={handleSortingPapersChange}
            />
            <ViewModeToggle />
          </>
        )}
      {isFilterActive && isHome ? (
        <>
          <div className="px-4">
            <SearchResultsSummary label="Papers" count={papers?.count || 0} />
          </div>
          <ViewModeToggle />
          <PapersHomeList
            updatePage={updatePageForFilter}
            isTripleView={isTripleView}
            currentPaperPage={currentPaperPage}
          />
        </>
      ) : isFilterActive ? (
        <PapersFilterList
          isCommunity={isCommunity}
          updatePage={updatePageForFilter}
          isTripleView={isTripleView}
        />
      ) : isHome ? (
        <PapersHomeList
          updatePage={updatePage}
          isTripleView={isTripleView}
          currentPaperPage={currentPaperPage}
        />
      ) : isCommunity ? (
        <PapersCommunityList
          updatePage={updatePage}
          urlKey={urlKey}
          isTripleView={isTripleView}
          currentDiscussionPage={currentPaperPage}
        />
      ) : (
        <PapersTopicList
          updatePage={updatePage}
          urlKey={urlKey}
          isTripleView={isTripleView}
          currentDiscussionPage={currentPaperPage}
        />
      )}
    </div>
  );
};
