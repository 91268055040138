import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPaperPaged } from '../../types/papers.type';
import { env } from '../../env';
import { IPaperFilterData } from '../../types/filter.type';
import { isFilterActive } from '../../utils/helperFunctions';

export const getPapersByTopicUrlKey = createAsyncThunk<
  IPaperPaged,
  {
    page: number;
    pageSize: number;
    urlKey: string;
    sortMethod?: string;
    signal: AbortSignal;
  }
>(
  'get/getPapersByTopicUrlKey',
  async (
    { page, pageSize, urlKey, sortMethod, signal },
    { rejectWithValue }
  ) => {
    const query =
      `pageNum=${page}&pageSize=${pageSize}` +
      (sortMethod ? `&order=${sortMethod}` : '');
    const url = `${env.apiUrl}/api/v1/topics/${urlKey}/papers?${query}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        signal,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch papers by topic URL key');
      }

      const data: IPaperPaged = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue('Failed to fetch papers by topic URL key');
    }
  }
);

export const getPapersByCommunityUrlKey = createAsyncThunk<
  IPaperPaged,
  {
    page: number;
    pageSize: number;
    urlKey: string;
    sortMethod?: string;
    signal: AbortSignal;
  }
>(
  'get/getPapersByCommunityUrlKey',
  async (
    { page, pageSize, urlKey, sortMethod, signal },
    { rejectWithValue }
  ) => {
    const query =
      `pageNum=${page}&pageSize=${pageSize}` +
      (sortMethod ? `&order=${sortMethod}` : '');
    const url = `${env.apiUrl}/api/v1/communities/${urlKey}/papers?${query}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        signal,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch papers by community URL key');
      }

      const data: IPaperPaged = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue('Failed to fetch papers by community URL key');
    }
  }
);

export const searchPapers = createAsyncThunk<
  IPaperPaged,
  { term: string; page: number; abortController: AbortController }
>(
  'get/searchPapers',
  async ({ term, page, abortController }, { rejectWithValue }) => {
    const url = `${env.apiUrl}/api/v1/papers/search?q=${encodeURIComponent(term)}&pageNum=${page}`;

    const signal = abortController.signal;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        signal
      });

      if (response.status === 404) {
        return rejectWithValue('No results found for your search query');
      }

      if (!response.ok) {
        return rejectWithValue(
          'We couldn&apos;t process your search at this minute. We&apos;reactively working on improving this site and will iron out any issues. In the meantime, please search for something different.'
        );
      }

      const data: IPaperPaged = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const getPapersHome = createAsyncThunk<
  IPaperPaged,
  {
    page?: number;
    pageSize?: number;
    sortMethod?: string;
    signal: AbortSignal;
    filterData?: IPaperFilterData;
  }
>(
  'get/getPapersHome',
  async (
    { page, pageSize, sortMethod, signal, filterData },
    { rejectWithValue }
  ) => {
    try {
      let query: string;
      let url: string;

      if (filterData && isFilterActive(filterData)) {
        const {
          pageNum = 1,
          pageSize: fs = 10,
          title,
          author,
          journal,
          publishedDateFrom,
          publishedDateTo
        } = filterData;

        query =
          `pageNum=${pageNum}&pageSize=${fs}` +
          (title ? `&title=${encodeURIComponent(title)}` : '') +
          (author ? `&author=${encodeURIComponent(author)}` : '') +
          (journal ? `&journal=${encodeURIComponent(journal)}` : '') +
          (publishedDateFrom
            ? `&publishedDateFrom=${encodeURIComponent(publishedDateFrom)}`
            : '') +
          (publishedDateTo
            ? `&publishedDateTo=${encodeURIComponent(publishedDateTo)}`
            : '');

        url = `${env.apiUrl}/api/v1/papers/filter?${query}`;
      } else {
        const p = page ?? 1;
        const s = pageSize ?? 10;
        const o = sortMethod ? `&order=${encodeURIComponent(sortMethod)}` : '';
        query = `pageNum=${p}&pageSize=${s}${o}`;
        url = `${env.apiUrl}/api/v1/papers?${query}`;
      }

      const response = await fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        signal
      });

      if (!response.ok) {
        throw new Error('Failed to fetch home papers.');
      }

      const data = (await response.json()) as IPaperPaged;
      return data;
    } catch (error) {
      return rejectWithValue('Failed to fetch home papers.');
    }
  }
);
