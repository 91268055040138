import { FC } from 'react';
import { Tooltip } from '../../components/core/tooltip';
import { formatCount } from '../../components/papersDiscussionRadio/hooks/usePaperCount';

interface SearchResultsSummaryProps {
  label: string;
  count: number;
}

export const SearchResultsSummary: FC<SearchResultsSummaryProps> = ({
  label,
  count
}) => {
  const isLargeCount = count > 999;
  const resultText = count === 1 ? 'result' : 'results';

  return (
    <div className="flex w-full pt-0 m-auto mb-2 mt-2 max-w-3xl">
      <div className="search-results-summary">
        <div className="type-count">
          <span className="text-primary-500 text-sm">
            Found {isLargeCount && 'about '}
            {isLargeCount ? (
              <Tooltip
                className="tooltipComments tooltipSearch"
                text={`${count} ${label}`}
              >
                <span className="formatted-count">{formatCount(count)}</span>
              </Tooltip>
            ) : (
              <span className="formatted-count">{formatCount(count)}</span>
            )}{' '}
            {resultText}
          </span>
        </div>
      </div>
    </div>
  );
};
