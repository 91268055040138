import { toast, ToastOptions } from 'react-toastify';
import { useAppDispatch } from '../../../state';
import { useCallback } from 'react';
import {
  clearNotifications,
  removeNotification
} from '../../../state/slices/notificationsReducer';

interface UseNotifications {
  notify: (
    type: 'info' | 'success' | 'warning' | 'error',
    message: string,
    options?: ToastOptions
  ) => void;
  dismiss: (id: string) => void;
  clearAll: () => void;
}

export const useNotifications = (): UseNotifications => {
  const dispatch = useAppDispatch();

  const notify = useCallback(
    (
      type: 'info' | 'success' | 'warning' | 'error',
      message: string,
      options?: ToastOptions
    ) => {
      switch (type) {
        case 'success':
          toast.success(message, options);
          break;
        case 'error':
          toast.error(message, options);
          break;
        case 'warning':
          toast.warning(message, options);
          break;
        case 'info':
        default:
          toast.info(message, options);
          break;
      }
    },
    []
  );

  const dismiss = useCallback(
    (id: string) => {
      dispatch(removeNotification(id));
    },
    [dispatch]
  );

  const clearAll = useCallback(() => {
    dispatch(clearNotifications());
  }, [dispatch]);

  return { notify, dismiss, clearAll };
};
