import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast, ToastContainer, Slide } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { AppState } from '../../state';
import { removeNotification } from '../../state/slices/notificationsReducer';

const NotificationsList: React.FC = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state: AppState) => state.notifications.notifications
  );

  useEffect(() => {
    notifications.forEach((notification) => {
      switch (notification.type) {
        case 'success':
          toast.success(notification.message, {
            onClose: () => dispatch(removeNotification(notification.id)),
            transition: Slide
          });
          break;
        case 'error':
          toast.error(notification.message, {
            onClose: () => dispatch(removeNotification(notification.id)),
            transition: Slide
          });
          break;
        case 'warning':
          toast.warning(notification.message, {
            onClose: () => dispatch(removeNotification(notification.id)),
            transition: Slide
          });
          break;
        case 'info':
        default:
          toast.info(notification.message, {
            onClose: () => dispatch(removeNotification(notification.id)),
            transition: Slide
          });
          break;
      }
    });
  }, [notifications, dispatch]);

  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  );
};

export default NotificationsList;
