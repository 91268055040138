import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../state';
import { useNavigate, useLocation } from 'react-router-dom';
import { setTopicsCommunitiesRadioState } from '../../state/slices/tabsReducer';
import { TOPICS_COMMUNITIES_RADIO_STATES } from '../constants';
import CommunityListContainer from '../community/communityListContainer';
import { TopicsListContainer } from '../topic/topicsListContainer';
import { createLink } from '../../utils/helperFunctions';
import {
  DiscussionsList,
  PapersList
} from '../../components/papersDiscussionRadio';
import { resetHomePaperFilterData } from '../../state/slices/filterPapersReducer';

type TabState =
  (typeof TOPICS_COMMUNITIES_RADIO_STATES)[keyof typeof TOPICS_COMMUNITIES_RADIO_STATES];

export const HomepageContainer: React.FC<unknown> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const radioState = useAppSelector(
    (state) => state.tabs.topicsCommunitiesRadioState
  );

  useEffect(() => {
    const path = location.pathname;

    let newTab: TabState = TOPICS_COMMUNITIES_RADIO_STATES.COMMUNITIES;
    if (path.includes(`/${TOPICS_COMMUNITIES_RADIO_STATES.TOPICS}`)) {
      newTab = TOPICS_COMMUNITIES_RADIO_STATES.TOPICS;
    } else if (path.includes(`/${TOPICS_COMMUNITIES_RADIO_STATES.PAPERS}`)) {
      dispatch(resetHomePaperFilterData());
      newTab = TOPICS_COMMUNITIES_RADIO_STATES.PAPERS;
    } else if (
      path.includes(`/${TOPICS_COMMUNITIES_RADIO_STATES.DISCUSSIONS}`)
    ) {
      newTab = TOPICS_COMMUNITIES_RADIO_STATES.DISCUSSIONS;
    }
    dispatch(setTopicsCommunitiesRadioState(newTab));
  }, [location.pathname, dispatch]);

  const handleTabChange = (newTab: TabState) => {
    dispatch(setTopicsCommunitiesRadioState(newTab));
    navigate(createLink(`/${newTab}`));
  };

  return (
    <div className="flex flex-col h-full w-full ">
      <main className="flex flex-col justify-center w-full">
        <div className="tabs-row flex justify-center px-4">
          <div className="tabs tabs4oneRow max-w-3xl w-full">
            <button
              className={`py-4 ${
                radioState === TOPICS_COMMUNITIES_RADIO_STATES.PAPERS
                  ? 'active'
                  : ''
              }`}
              onClick={() =>
                handleTabChange(TOPICS_COMMUNITIES_RADIO_STATES.PAPERS)
              }
            >
              All Papers
            </button>
            <button
              className={`py-4 ${
                radioState === TOPICS_COMMUNITIES_RADIO_STATES.DISCUSSIONS
                  ? 'active'
                  : ''
              }`}
              onClick={() =>
                handleTabChange(TOPICS_COMMUNITIES_RADIO_STATES.DISCUSSIONS)
              }
            >
              All Discussions
            </button>
            <button
              className={`py-4 ${radioState === TOPICS_COMMUNITIES_RADIO_STATES.COMMUNITIES ? 'active' : ''}`}
              onClick={() =>
                handleTabChange(TOPICS_COMMUNITIES_RADIO_STATES.COMMUNITIES)
              }
            >
              Communities
            </button>
            <button
              className={`py-4 ${radioState === TOPICS_COMMUNITIES_RADIO_STATES.TOPICS ? 'active' : ''}`}
              onClick={() =>
                handleTabChange(TOPICS_COMMUNITIES_RADIO_STATES.TOPICS)
              }
            >
              Topics
            </button>
          </div>
        </div>
      </main>

      <div className="flex w-full flex-row h-full homeLists">
        <div className="flex w-full flex-col">
          {radioState === TOPICS_COMMUNITIES_RADIO_STATES.TOPICS && (
            <TopicsListContainer />
          )}
          {radioState === TOPICS_COMMUNITIES_RADIO_STATES.COMMUNITIES && (
            <CommunityListContainer />
          )}
          {radioState === TOPICS_COMMUNITIES_RADIO_STATES.PAPERS && (
            <PapersList isHome={true} />
          )}
          {radioState === TOPICS_COMMUNITIES_RADIO_STATES.DISCUSSIONS && (
            <DiscussionsList isHome={true} />
          )}
        </div>
      </div>
    </div>
  );
};
