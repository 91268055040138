import {
  COMMENT_SORT_METHODS,
  CommentSortMethod,
  COMMUNITY_SORT_METHODS,
  CommunitySortMethod,
  DISCUSSION_HOME_SORT_METHODS,
  DISCUSSION_SORT_METHODS,
  DiscussionHomeSortMethod,
  DiscussionSortMethod,
  PAPER_COMMUNITY_SORT_METHODS,
  PAPER_HOME_SORT_METHODS,
  PAPER_TOPIC_SORT_METHODS,
  PaperCommunitySortMethod,
  PaperHomeSortMethod,
  PaperTopicSortMethod,
  TOPIC_SORT_METHODS,
  TopicSortMethod,
  USERPOSTS_SORT_METHODS,
  UserPostsSortMethod
} from '../../utils/sortingConst';
import {
  SET_COMMENT_SORT_METHOD,
  SET_COMMUNITY_SORT_METHOD,
  SET_DISCUSSION_SORT_METHOD,
  SET_DISCUSSIONS_HOME_SORT_METHOD,
  SET_PAPER_COMMUNITY_SORT_METHOD,
  SET_PAPER_HOME_SORT_METHOD,
  SET_PAPER_TOPIC_SORT_METHOD,
  SET_TOPIC_SORT_METHOD,
  SET_USERPOSTS_SORT_METHOD,
  SortingActions
} from '../actions/sortingActions';

interface SortingState {
  discussionSortMethod: DiscussionSortMethod;
  paperCommunitySortMethod: PaperCommunitySortMethod;
  paperTopicSortMethod: PaperTopicSortMethod;
  topicSortMethod: TopicSortMethod;
  communitySortMethod: CommunitySortMethod;
  commentSortMethod: CommentSortMethod;
  userPostsSortMethod: UserPostsSortMethod;
  paperHomeSortMethod: PaperHomeSortMethod;
  discussionHomeSortMethod: DiscussionHomeSortMethod;
}

const initialSortingState: SortingState = {
  discussionSortMethod: DISCUSSION_SORT_METHODS.LATEST_DISCUSSION,
  paperCommunitySortMethod: PAPER_COMMUNITY_SORT_METHODS.COMMUNITY_PAPERS_HOT,
  paperTopicSortMethod: PAPER_TOPIC_SORT_METHODS.TOPIC_PAPERS_HOT,
  topicSortMethod: TOPIC_SORT_METHODS.LATEST_DISCUSSION,
  communitySortMethod: COMMUNITY_SORT_METHODS.LATEST_DISCUSSION,
  commentSortMethod: COMMENT_SORT_METHODS.CREATED_DESC,
  userPostsSortMethod: USERPOSTS_SORT_METHODS.LAST_COMMENT_DESC,
  paperHomeSortMethod: PAPER_HOME_SORT_METHODS.HOME_PAPERS_HOT,
  discussionHomeSortMethod: DISCUSSION_HOME_SORT_METHODS.HOME_CREATED_DESC
};

const sortingReducer = (
  state: SortingState = initialSortingState,
  action: SortingActions
): SortingState => {
  switch (action.type) {
    case SET_DISCUSSION_SORT_METHOD:
      return {
        ...state,
        discussionSortMethod: action.payload
      };
    case SET_PAPER_COMMUNITY_SORT_METHOD:
      return {
        ...state,
        paperCommunitySortMethod: action.payload
      };
    case SET_PAPER_TOPIC_SORT_METHOD:
      return {
        ...state,
        paperTopicSortMethod: action.payload
      };
    case SET_TOPIC_SORT_METHOD:
      return {
        ...state,
        topicSortMethod: action.payload
      };
    case SET_COMMUNITY_SORT_METHOD:
      return {
        ...state,
        communitySortMethod: action.payload
      };
    case SET_COMMENT_SORT_METHOD:
      return {
        ...state,
        commentSortMethod: action.payload
      };
    case SET_USERPOSTS_SORT_METHOD:
      return {
        ...state,
        userPostsSortMethod: action.payload
      };
    case SET_PAPER_HOME_SORT_METHOD:
      return {
        ...state,
        paperHomeSortMethod: action.payload
      };
    case SET_DISCUSSIONS_HOME_SORT_METHOD:
      return {
        ...state,
        discussionHomeSortMethod: action.payload
      };

    default:
      return state;
  }
};

export default sortingReducer;
