import { useAppDispatch, useAppSelector } from '../../state';
import {
  setDiscussionHomeSortMethod,
  setDiscussionSortMethod
} from '../../state/actions/sortingActions';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import qs from 'qs';
import { DiscussionCommunityList } from './discussions-community-list';
import { DiscussionTopicList } from './discussions-topic-list';
import { ViewModeToggle } from '../viewModeToggle';
import { useQueryParams } from '../../hooks/useQueryParams';
import { SortingButtonsContainer } from '../sortingButtons/sortingButtonsContainer';
import {
  DISCUSSION_HOME_SORT_METHOD_DISPLAY_NAMES,
  DISCUSSION_SORT_METHOD_DISPLAY_NAMES,
  DiscussionHomeSortMethod,
  DiscussionSortMethod
} from '../../utils/sortingConst';
import { selectGeneralViewMode } from '../../state/selectors/viewModeSelector';
import { DiscussionsHomeList } from './discussions-home-list';

interface DiscussionsContentProps {
  isCommunity?: boolean;
  isHome?: boolean;
}

export const DiscussionsList: FC<DiscussionsContentProps> = ({
  isCommunity,
  isHome
}) => {
  const dispatch = useAppDispatch();
  const { urlKey } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const viewMode = useAppSelector(selectGeneralViewMode);
  const isTripleView = viewMode === 'triple';

  const queryParams = useQueryParams();
  const initialPage = queryParams.p ? parseInt(queryParams.p as string, 10) : 1;
  const [currentDiscussionPage, setCurrentDiscussionPage] =
    useState(initialPage);

  const discussionSortMethod = useAppSelector((state) => {
    if (isHome) {
      return state.sort.discussionHomeSortMethod;
    }
    return state.sort.discussionSortMethod;
  });

  const discussionSortMethodDisplay = isHome
    ? DISCUSSION_HOME_SORT_METHOD_DISPLAY_NAMES
    : DISCUSSION_SORT_METHOD_DISPLAY_NAMES;

  const updatePage = (newPage: number) => {
    setCurrentDiscussionPage(newPage);
    const newSearch = qs.stringify({ p: newPage }, { addQueryPrefix: true });
    navigate({ pathname: location.pathname, search: newSearch });
  };

  const handleSortingDiscussionsChange = useCallback(
    (method: DiscussionSortMethod | DiscussionHomeSortMethod) => {
      if (isHome) {
        dispatch(
          setDiscussionHomeSortMethod(method as DiscussionHomeSortMethod)
        );
      } else {
        dispatch(setDiscussionSortMethod(method as DiscussionSortMethod));
      }
    },
    [dispatch, isHome]
  );

  useEffect(() => {
    const newPage = queryParams.p ? parseInt(queryParams.p as string, 10) : 1;
    setCurrentDiscussionPage(newPage);
  }, [location.search, queryParams]);

  return (
    <div className="w-full h-full">
      <SortingButtonsContainer
        sortMethods={discussionSortMethodDisplay}
        currentSortMethod={discussionSortMethod}
        onSortMethodChange={handleSortingDiscussionsChange}
      />
      <ViewModeToggle />
      {isHome ? (
        <DiscussionsHomeList
          updatePage={updatePage}
          isTripleView={isTripleView}
          currentDiscussionPage={currentDiscussionPage}
        />
      ) : isCommunity ? (
        <DiscussionCommunityList
          updatePage={updatePage}
          urlKey={urlKey}
          isTripleView={isTripleView}
          currentDiscussionPage={currentDiscussionPage}
          discussionSortMethod={discussionSortMethod as DiscussionSortMethod}
        />
      ) : (
        <DiscussionTopicList
          updatePage={updatePage}
          urlKey={urlKey}
          isTripleView={isTripleView}
          currentDiscussionPage={currentDiscussionPage}
          discussionSortMethod={discussionSortMethod as DiscussionSortMethod}
        />
      )}
    </div>
  );
};
