export const ALLOWED_TAGS = [
  'a',
  'p',
  'div',
  'span',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'ul',
  'ol',
  'li',
  'strong',
  'del',
  's',
  'u',
  'em',
  'blockquote',
  'code',
  'table',
  'tr',
  'td',
  'th',
  'thead',
  'pre',
  'sub',
  'sup',
  'hr',
  'br',
  'i'
];

export const ALLOWED_ATTR = ['href', 'target', 'class', 'id', 'style', 'rel'];

/**
 * Common URL prefix for routing across the App.
 * If necessary turn it off set an empty string to the constant.
 */
export const PUBLIC_URL_PREFIX = '';
