import React from 'react';
import { useAppDispatch } from '../../state';
import {
  setHomePaperFilterData,
  resetHomePaperFilterData
} from '../../state/slices/filterPapersReducer';
import { PapersMainFilter } from '.';
import { getPapersHome } from '../../state/actions/papersActions';

export const PapersHomeFilter: React.FC = () => {
  const dispatch = useAppDispatch();

  return (
    <PapersMainFilter
      setFilterData={(filterData) =>
        dispatch(setHomePaperFilterData(filterData))
      }
      fetchData={(filterData) => dispatch(getPapersHome(filterData))}
      resetFilterData={() => dispatch(resetHomePaperFilterData())}
      type="home"
    />
  );
};
