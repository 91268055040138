import { useEffect, useState, useMemo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../../state';
import {
  resetCommunityPaperFilterData,
  resetHomePaperFilterData,
  resetTopicPaperFilterData
} from '../../../state/slices/filterPapersReducer';
import { serializeFilters } from '../utils/serializeFilters';

interface FilterData {
  title: string;
  author: string;
  journal: string;
  publishedDateFrom: string;
  publishedDateTo: string;
  pageNum: number;
  pageSize: number;
}
type PaperFilterArgs = {
  filterData: FilterData;
  urlKey?: string;
};

interface UsePapersFilterProps {
  urlKey?: string;
  setFilterData: (filterData: FilterData) => void;
  fetchData: (args: PaperFilterArgs) => void;
  resetFilterData: () => void;
  initialSerializedFilters: string;
}

export const usePapersFilter = ({
  urlKey,
  setFilterData,
  fetchData,
  resetFilterData,
  initialSerializedFilters
}: UsePapersFilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [lastAppliedFilters, setLastAppliedFilters] = useState<string>(
    initialSerializedFilters
  );
  const dispatch = useAppDispatch();

  // Extract initial filter values from URL
  const urlInitialValues = useMemo(
    () => ({
      title: searchParams.get('title') || '',
      publishedDateFrom: searchParams.get('publishedDateFrom') || '',
      publishedDateTo: searchParams.get('publishedDateTo') || '',
      author: searchParams.get('author') || '',
      journal: searchParams.get('journal') || ''
    }),
    [searchParams]
  );

  // Prepare initial values for input fields (e.g., extracting the year)
  const urlInitialValuesForInputs = useMemo(
    () => ({
      ...urlInitialValues,
      publishedDateFrom: urlInitialValues.publishedDateFrom.split('-')[0],
      publishedDateTo: urlInitialValues.publishedDateTo.split('-')[0]
    }),
    [urlInitialValues]
  );

  // Function to check if any filter parameters are present (excluding pageNum and pageSize)
  const hasFilterParams = useCallback((): boolean => {
    return (
      searchParams.has('title') ||
      searchParams.has('publishedDateFrom') ||
      searchParams.has('publishedDateTo') ||
      searchParams.has('author') ||
      searchParams.has('journal')
    );
  }, [searchParams]);

  // Check if all filter fields are empty
  const isAllFieldsEmpty = useCallback(
    (values: typeof urlInitialValuesForInputs): boolean =>
      !values.title &&
      !values.publishedDateFrom &&
      !values.publishedDateTo &&
      !values.author &&
      !values.journal,
    []
  );

  // Function to update the URL with new filter and pagination parameters
  const updateURLWithFilters = useCallback(
    (values: typeof urlInitialValues, pageNum: number, pageSize: number) => {
      const formattedValues = { ...values };

      // Format date ranges
      if (values.publishedDateFrom && !values.publishedDateTo) {
        formattedValues.publishedDateFrom = `${values.publishedDateFrom}-01-01`;
      } else if (values.publishedDateFrom && values.publishedDateTo) {
        formattedValues.publishedDateFrom = `${values.publishedDateFrom}-01-01`;
        formattedValues.publishedDateTo = `${values.publishedDateTo}-12-31`;
      } else {
        formattedValues.publishedDateFrom = '';
        formattedValues.publishedDateTo = '';
      }

      // Remove empty values
      const filteredValues = Object.fromEntries(
        Object.entries(formattedValues).filter(([, value]) => value)
      );
      // Set new search parameters including pagination
      const params = new URLSearchParams({
        ...filteredValues,
        pageNum: pageNum.toString(),
        pageSize: pageSize.toString()
      });

      setSearchParams(params);
    },
    [setSearchParams]
  );

  /**
   * Handle applying new filters.
   * Updates the URL which in turn triggers `handleFilterUpdate` via `useEffect`.
   */
  const handleApply = useCallback(
    (values: any, pageNum = 1, pageSize = 10) => {
      updateURLWithFilters(values, pageNum, pageSize);
    },
    [updateURLWithFilters]
  );

  /**
   * Handle resetting filters.
   * Resets the form, updates the URL, and dispatches reset actions.
   */
  const handleReset = useCallback(
    (
      resetForm: () => void,
      setFieldValue: (field: string, value: any) => void,
      values: any
    ) => {
      if (isAllFieldsEmpty(values)) {
        return;
      }

      resetForm();
      setFieldValue('publishedDateFrom', '');
      setFieldValue('publishedDateTo', '');

      const resetValues = {
        title: '',
        publishedDateFrom: '',
        publishedDateTo: '',
        author: '',
        journal: ''
      };

      const filteredValues = Object.fromEntries(
        Object.entries(resetValues).filter(([, value]) => value)
      );

      const params = new URLSearchParams(filteredValues);
      setSearchParams(params);
      setLastAppliedFilters('');
      resetFilterData();
      dispatch(resetTopicPaperFilterData());
      dispatch(resetCommunityPaperFilterData());
      dispatch(resetHomePaperFilterData());
    },
    [isAllFieldsEmpty, setSearchParams, resetFilterData, dispatch]
  );

  /**
   * Handle filter and pagination updates.
   * Only fetches data when filters (excluding pageNum and pageSize) are present and have changed.
   */
  const handleFilterUpdate = useCallback(() => {
    const pageNum = searchParams.get('pageNum')
      ? parseInt(searchParams.get('pageNum')!, 10)
      : 1;
    const pageSize = searchParams.get('pageSize')
      ? parseInt(searchParams.get('pageSize')!, 10)
      : 10;

    const title = searchParams.get('title') || '';
    const author = searchParams.get('author') || '';
    const journal = searchParams.get('journal') || '';
    const publishedDateFrom = searchParams.get('publishedDateFrom') || '';
    let publishedDateTo = searchParams.get('publishedDateTo') || '';

    // Handle partial date ranges
    if (publishedDateFrom && !publishedDateTo) {
      const yearMatch = publishedDateFrom.match(/^(\d{4})/);
      const year = yearMatch ? yearMatch[1] : '';
      if (year) {
        publishedDateTo = `${year}-12-31`;
      }
    }

    const filterWithPagination: FilterData = {
      title,
      author,
      journal,
      publishedDateFrom,
      publishedDateTo,
      pageNum,
      pageSize
    };

    // Serialize current filters excluding pagination parameters
    const serializedCurrentFilters = serializeFilters(filterWithPagination);
    // Check if any filter parameters are present
    const filtersPresent = hasFilterParams();
    if (
      filtersPresent &&
      (serializedCurrentFilters !== lastAppliedFilters ||
        serializedCurrentFilters !== initialSerializedFilters)
    ) {
      if (urlKey) {
        fetchData({
          filterData: filterWithPagination,
          urlKey
        });
      } else {
        fetchData({ filterData: filterWithPagination });
      }

      setFilterData(filterWithPagination);
      setLastAppliedFilters(serializedCurrentFilters);
    }
  }, [
    searchParams,
    hasFilterParams,
    lastAppliedFilters,
    initialSerializedFilters,
    fetchData,
    urlKey,
    setFilterData
  ]);

  // Effect to handle filter and pagination updates whenever searchParams change
  useEffect(() => {
    handleFilterUpdate();
  }, [searchParams, handleFilterUpdate]);

  return {
    handleApply,
    handleReset,
    handleFilterUpdate,
    urlInitialValuesForInputs,
    isAllFieldsEmpty,
    isSameAsLast: (currentFilters: string) =>
      currentFilters === lastAppliedFilters
  };
};
