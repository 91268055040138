import { FC, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Paragraph,
  Flex,
  HeadingOne,
  HeadingTwo,
  Button
} from '../../../atoms';
import { texts } from '../../../text';
import {
  Checkbox,
  RequiredFieldLabel,
  TextareaLetterCount,
  Validation
} from '../../../components/form';
import TopicTagsInput from '../../../components/form/topicTagsInput';
import { InputLetterCount } from '../../../components/form/inputLetterCount';
import { Tag } from '../../../types/topic.type';
import { AccordionItem } from '../../../components/accordion';
import { RenderHTML } from '../../../components/paper/render-html';

interface EditProps {
  initialCommunityName: string;
  initialDescription: string;
  selectedTopicTags: Tag[];
  topicTags: Tag[];
  removeTopic: (topicUrlKey: string) => void;
  filterTopics: (event: React.FormEvent<HTMLInputElement>) => void;
  addTopic: (elem: string) => void;
  handleUpdate: () => void;
  setCommunityName: (name: string) => void;
  setDescription: (description: string) => void;
  errors: Record<string, string>;
  inputTopicsValue: string;
  isUserAdmin: boolean;
  isUserCreator: boolean;
  fetchMoreTopics: () => void;
  hasMoreTopicPage: boolean;
  isSubscribedToNews: boolean;
  setIsSubscribedToNews: () => void;
}

const EditCommunitySchema = Yup.object().shape({
  communityName: Yup.string()
    .required('Required')
    .min(3, 'Minimum length is 3 characters'),
  description: Yup.string()
    .required('Required')
    .min(3, 'Minimum length is 3 characters'),
  isChecked: Yup.boolean().oneOf([true], 'Required'),
  isSubscribedToNews: Yup.boolean()
});

export const EditCommunity: FC<EditProps> = ({
  topicTags,
  removeTopic,
  addTopic,
  handleUpdate,
  filterTopics,
  isUserAdmin,
  isUserCreator,
  inputTopicsValue,
  selectedTopicTags,
  initialCommunityName,
  initialDescription,
  setCommunityName,
  setDescription,
  fetchMoreTopics,
  hasMoreTopicPage,
  errors,
  isSubscribedToNews,
  setIsSubscribedToNews
}) => {
  const [isChecked, setIsChecked] = useState(true);
  const [isExpandedTips, setIsExpandedTips] = useState<boolean>(true);

  const handleToggleTips = () => {
    setIsExpandedTips((prev: any) => !prev);
  };

  const formik = useFormik({
    initialValues: {
      communityName: initialCommunityName,
      description: initialDescription,
      isChecked,
      isSubscribedToNews
    },
    validationSchema: EditCommunitySchema,
    onSubmit: () => {
      handleUpdate();
    },
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true
  });

  const handleNameChange = useMemo(() => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      formik.handleChange(e);
      setCommunityName(e.target.value);
    };
  }, [formik, setCommunityName]);

  const handleDescriptionChange = useMemo(() => {
    return (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      formik.handleChange(e);
      setDescription(e.target.value);
    };
  }, [formik, setDescription]);

  const handleIsSubscribedToNewsChange = useMemo(() => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsSubscribedToNews();
      formik.setFieldValue('isSubscribedToNews', e.target.checked);
    };
  }, [formik, setIsSubscribedToNews]);

  return (
    <form
      className="w-full mx-auto filters"
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit(e);
      }}
      noValidate
    >
      <main className="page-content max-w-2/3">
        <Flex direction="flex-row m-auto">
          <Flex className="m-auto max-w-3xl">
            <HeadingOne color="black">{texts.editCommunity}</HeadingOne>
            <div
              className="mb-0 text-xs text-black"
              dangerouslySetInnerHTML={{ __html: texts.editCommunityText }}
            ></div>
            <HeadingTwo
              className={`font-black ${
                !isUserAdmin && !isUserCreator
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
            >
              <RequiredFieldLabel label={texts.communityName} />
            </HeadingTwo>
            <Paragraph
              className={`mb-4 ${
                !isUserAdmin && !isUserCreator
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
              size="xs"
              color="black"
            >
              {texts.communityNameDescription}
            </Paragraph>
            <InputLetterCount
              value={formik.values.communityName}
              setValue={handleNameChange}
              onBlur={formik.handleBlur}
              maxLength={180}
              placeholder={texts.communityName}
              name="communityName"
              error={
                formik.touched.communityName && formik.errors.communityName
                  ? formik.errors.communityName
                  : undefined
              }
              touched={formik.touched.communityName}
              disabled={!isUserAdmin && !isUserCreator}
            />
            <HeadingTwo
              className={`font-black ${
                !isUserAdmin && !isUserCreator
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
            >
              <RequiredFieldLabel label={texts.description} />
            </HeadingTwo>
            <Paragraph
              className={`mb-4 ${
                !isUserAdmin && !isUserCreator
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
              size="xs"
              color="black"
            >
              {texts.descriptionExplain}
            </Paragraph>
            <TextareaLetterCount
              value={formik.values.description}
              setValue={handleDescriptionChange}
              onBlur={formik.handleBlur}
              maxLength={1024}
              placeholder={texts.description}
              name="description"
              error={
                formik.touched.description && formik.errors.description
                  ? formik.errors.description
                  : undefined
              }
              touched={formik.touched.description}
              disabled={!isUserAdmin && !isUserCreator}
            />
            <HeadingTwo>{texts.topicTags}</HeadingTwo>
            <TopicTagsInput
              topicTags={topicTags}
              selectedTopicTags={selectedTopicTags}
              onAddTopic={addTopic}
              filterTopics={filterTopics}
              inputTopicsValue={inputTopicsValue}
              onRemoveTopic={removeTopic}
              isUserAdmin={isUserAdmin}
              isUserCreator={isUserCreator}
              explanationText={texts.topicTagsExplain}
              maxLimit={100}
              fetchMoreTopics={fetchMoreTopics}
              hasMore={hasMoreTopicPage}
            />
            <AccordionItem
              title={`${texts.topicsTipsTitle}`}
              isExpanded={isExpandedTips}
              onToggle={handleToggleTips}
              className={'topicAcc p-0 text-sm rounded-none'}
            >
              <div className="text-xs">
                <RenderHTML htmlContent={texts.topicsTipsContent} />
              </div>
            </AccordionItem>
            <div className="mb-4">
              <Checkbox
                label={texts.checkbox}
                required={true}
                checked={formik.values.isChecked}
                disabled={!isUserAdmin}
                onChange={(e) => {
                  formik.setFieldValue('isChecked', e.target.checked);
                  setIsChecked(e.target.checked);
                }}
                className={`${
                  formik.touched.isChecked && formik.errors.isChecked
                    ? 'border-error-500'
                    : ''
                }`}
              />
              {formik.touched.isChecked && formik.errors.isChecked && (
                <Validation message={formik.errors.isChecked} />
              )}
            </div>
            <div className="mb-8">
              <Checkbox
                label={texts.isSubscribedToNews}
                required={false}
                checked={formik.values.isSubscribedToNews}
                onChange={handleIsSubscribedToNewsChange}
                className={`${
                  formik.touched.isSubscribedToNews &&
                  formik.errors.isSubscribedToNews
                    ? 'border-error-500'
                    : 'border-gray-500'
                }`}
              ></Checkbox>
              {formik.touched.isSubscribedToNews &&
                formik.errors.isSubscribedToNews && (
                  <Validation message={formik.errors.isSubscribedToNews} />
                )}
            </div>
            <div className="flex justify-end">
              <Button
                className="mt-4 bg-gradient-to-r from-primaryLight to-primaryDefault inline fitContent desktop:px-20 laptop:px-20 px-10 text-small uppercase py-1 font-medium"
                rounded="md"
                size="imd"
                type="submit"
                disabled={!isUserAdmin && !isUserCreator}
              >
                {texts.updateCommunityButton}
              </Button>
            </div>
          </Flex>
        </Flex>
        {errors && (
          <div className="mt-4 validationCommunity">
            <Validation message={errors.updateError} />
          </div>
        )}
      </main>
    </form>
  );
};
