import { AppState } from '../store';

// Topic Selectors
export const selectTopicPaperFilterData = (state: AppState) =>
  state.filterPapers.topicPaperFilterData;
export const selectTopicPapersData = (state: AppState) =>
  state.filterPapers.topicPapersData;
export const selectTopicLoading = (state: AppState) =>
  state.filterPapers.topicLoading;
export const selectTopicError = (state: AppState) =>
  state.filterPapers.topicError;

// Community Selectors
export const selectCommunityPaperFilterData = (state: AppState) =>
  state.filterPapers.communityPaperFilterData;
export const selectCommunityPapersData = (state: AppState) =>
  state.filterPapers.communityPapersData;
export const selectCommunityLoading = (state: AppState) =>
  state.filterPapers.communityLoading;
export const selectCommunityError = (state: AppState) =>
  state.filterPapers.communityError;

// Home Selectors
export const selectHomePaperFilterData = (state: AppState) =>
  state.filterPapers.homePaperFilterData;
export const selectHomePapersData = (state: AppState) =>
  state.filterPapers.homePapersData;
export const selectHomeLoading = (state: AppState) =>
  state.filterPapers.homeLoading;
export const selectHomeError = (state: AppState) =>
  state.filterPapers.homeError;
