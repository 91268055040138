import { useEffect } from 'react';
import { DiscussionHomeSortMethod } from '../../../utils/sortingConst';
import { useAppDispatch, useAppSelector } from '../../../state';
import {
  selectDiscussionsHome,
  selectDiscussionsHomeError,
  selectDiscussionsHomeLoading
} from '../../../state/selectors/discussionsSelector';
import { getDiscussionsHome } from '../../../state/actions/discussionsActions';

interface UseGetDiscussionsHomeDataParams {
  page: number;
  pageSize: number;
  sortMethod: DiscussionHomeSortMethod;
}

export const useGetDiscussionsHomeData = ({
  page,
  pageSize,
  sortMethod
}: UseGetDiscussionsHomeDataParams) => {
  const dispatch = useAppDispatch();

  const discussions = useAppSelector(selectDiscussionsHome);
  const loading = useAppSelector(selectDiscussionsHomeLoading);
  const error = useAppSelector(selectDiscussionsHomeError);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchDiscussions = async () => {
      await dispatch(
        getDiscussionsHome({
          page,
          pageSize,
          sortMethod,
          signal
        })
      );
    };

    fetchDiscussions();

    return () => {
      controller.abort();
    };
  }, [dispatch, page, pageSize, sortMethod]);

  return { discussions, loading, error };
};
